/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar2.css";

import { FaBookmark } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";

import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import { IoMdContacts } from "react-icons/io";

import styled from "styled-components";
import DarkMode from "../Darkmode/DarkMode";
import { useEffect } from "react";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: #bc0000;
  z-index: 1;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar2 = () => {
  const [isOpen, setIsOpen] = useState(false);



 

  const navigate = useNavigate();
  return (
    <div className="nav-bg">
      <div className="fix-pro-nav">
        <div className="fle1-nav1">
          <div className="logo-1">
            <picture>
            <source
    // onClick={() => navigate("/")}
    // className="nav-logo"
    srcSet={require("../../Img/logo-dark.png")}
    media="(data-theme: dark)"
    // alt=""
  />
     
              <img
              onClick={() => navigate("/")}
              className="nav-logo"
              media="(prefers-color-scheme: light)"
              src={require("../../Img/logo.png")}
              alt=""
            />
            </picture>
           

          
        
          </div>

          <div className="nav-bb">
            <div className="log-sig-btn-1">
              {/* <DarkMode /> */}

              <button onClick={() => navigate("/")} className="login-b">
                HOME
              </button>
              <button onClick={() => navigate("/login")} className="login-b">
                LOG IN
              </button>
              <button onClick={() => navigate("/SignUp")} className="sign-bb">
                SIGN UP
              </button>
            </div>

            {/* mobile-menu */}
            <div className="mobile-menu-nav">
              <button onClick={() => setIsOpen(!isOpen)}>
                <GrMenu className="open-tab-mob" />
              </button>
              <StyledOffCanvas isOpen={isOpen}>
                <div className="head-mobile-menu1">
                  <ul className="ul-menu-mobile">
                    <li>
                      <div className="mobile-k-m">
                        <button
                          onClick={() => navigate("/login")}
                          className="login-b1"
                        >
                          <span onClick={() => setIsOpen(false)}>LOG IN</span>
                        </button>
                        <button
                          onClick={() => navigate("/SignUp")}
                          className="login-b1"
                        >
                          <span onClick={() => setIsOpen(false)}>SIGN UP</span>
                        </button>
                        <div>
                          <a onClick={() => setIsOpen(false)}>
                            <AiOutlineClose className="close-mobile-menu" />
                          </a>
                        </div>
                      </div>
                    </li>
                    <li></li>
                    <li>
                      <div className="side-menu-h1">
                        <AiOutlineHome />
                        <Link to="/">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            HOME
                          </span>
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="side-menu-h1">
                        <TbListDetails />
                        <Link to="/Course-details">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            COURSE DETAILS
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <FaBookmark />
                        <Link to="/AboutUs2">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            ABOUT US
                          </span>
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="side-menu-h1">
                        <IoMdContacts />
                        <Link to="/Contact2">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            CONTACT US
                          </span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </StyledOffCanvas>
              <StyledOffCanvasOverlay
                isOpen={isOpen}
                onClick={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Navbar2;
