/* eslint-disable react/jsx-pascal-case */
import { getAuth, getIdToken, getIdTokenResult } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { db } from "../../Firebase/Firebase";
import Footer from "../Footer/Footer ";
import "./Home.css";
import Fingerprint2 from "fingerprintjs2";
import sha256 from "crypto-js/sha256";
import { SHA256 } from "crypto-js";
import Progress_bar from "./ProgressBar";

const Home = () => {
  const { user } = useContext(AuthContext);

  const secretKey = `${user.uid}`;

  // async function generateDeviceId() {
  //   const components = await Fingerprint2.getPromise();
  //   const values = components.map((component) => component.value);
  //   const fingerprint = sha256(values.join("")).toString();
  //   const deviceId = sha256(`${fingerprint}:${secretKey}`).toString();
  //   console.log(deviceId);
  //   return deviceId;
  // }
  // useEffect(() => {
  //   generateDeviceId();
  // });

  const [getUser, setGetUser] = useState();
  const[totalMod,setTotalMod] = useState(0);

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      }
    });

    getDoc(doc(db, "ContestPrepAcademy" , "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalMod(docSnap.data().TotalModule);
       
      }
    });
  }, [user]);

  const navigate = useNavigate();

  return (
    <div className="bg-hh">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="home-side-bar-data">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y grid-c1 ">
              <div className="grid-c2">
                <div className="data-div-h ">
                  <div className="data-container">
                    {/* <div className="update-1">
          <h1>
            Female Module now live, CLICK HERE to find out more and
            purchase.
          </h1>
        </div> */}
                    <div className="update-1">
                      <h1> MODULE 3 - Recovery Uploaded </h1>
                    </div>
                    <div className="update-1">
                      <h1>Live stream will be announced shortly.</h1>
                    </div>
                    <div className="h1-courses">
                      <h1>COURSES</h1>
                    </div>

                    <div className="card-head-course">
                      <div className="card-head-data-j">
                        <div>
                          <img
                            className="ww"
                            src={require("../../Img/home2.png")}
                            alt=""
                            onClick={() => navigate("/Modules")}
                          />
                        </div>
                        <div className="card-h-certifi">
                          <h1>Contest Prep Certification</h1>
                        </div>
                        <div className="bodd-d">
                          <Progress_bar
                            bgcolor="#bc0000"
                            progress={(
                              ((parseInt(getUser?.Module1)) /
                              totalMod) *
                              100
                            ).toFixed(2)}
                            height={6}
                          />
                        </div>
                        <div>
                          <h1 className="percentage-course-com">
                          {(((parseInt(getUser?.Module1)) /
                                totalMod) *
                                100).toFixed(2)}
                            % Complete
                          </h1>
                        </div>
                        <div>
                          <button
                            onClick={() => navigate("/Modules")}
                            className="view-btn-hom"
                          >
                            View Lessons
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-c3">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
