import React, { useEffect, useRef, useState } from "react";
import {
  AiFillCloseCircle,
  AiOutlineClose,
  AiOutlineSend,
} from "react-icons/ai";

import Modal from "react-modal";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FileUploader } from "react-drag-drop-files";
import "./Replydata.css";
import { toast } from "react-hot-toast";
import Footer from "../Footer/Footer ";
import { useNavigate, useParams } from "react-router-dom";
import {
  onSnapshot,
  doc,
  addDoc,
  collection,
  getDoc,
  updateDoc,
  query,
  orderBy,
  increment,
} from "firebase/firestore";
import { db, storage } from "../../Firebase/Firebase";
import moment from "moment";
import { MdCancel, MdKeyboardVoice } from "react-icons/md";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import ReactAudioPlayer from "react-audio-player";

import {
  AiFillAudio,
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiOutlineGif,
} from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { RiSendPlane2Fill } from "react-icons/ri";
import { v4 } from "uuid";

const Replydata = () => {
  const { id } = useParams();
  const [forum, setForum] = useState();

  const { user } = useContext(AuthContext);
  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Forum", id), (doc) => {
      setForum(doc.data());
    });

    return () => {
      unsub();
    };
  }, [id]);

  const fileTypes = ["JPEG", "PNG"];
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [name, setName] = useState("");
  const [tags, setTags] = useState("");
  const [forumdata, setForumData] = useState({});

  const addReply = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(collection(db, "Forum", id, "reply"), {
            Image: url,
            reply: name,
            tags,
            user: getUser?.name,
            email: getUser?.email,
            createdAt: moment().format(),
            profileimg: getUser?.profileimg || "",
          });
          addDoc(collection(db, "usersLesson", forum.userId, "Notification"), {
            Notification: `Someone Replied on Your Forum`,
            markasRead: false,
          });
          updateDoc(doc(db, "usersLesson", forum.userId), {
            NotificationNumber: increment(1),
          })
            .then(() => {
              toast("Reply Send", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
              updateDoc(doc(db, "Forum", id), {
                lastreply: moment().format(),
                replies: increment(1),
              });
              setName("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(collection(db, "Forum", id, "reply"), {
        reply: name,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        createdAt: moment().format(),
        profileimg: getUser?.profileimg || "",
      });
      addDoc(collection(db, "usersLesson", forum.userId, "Notification"), {
        Notification: `Someone Replied on Your Forum`,
        markasRead: false,
      });
      updateDoc(doc(db, "usersLesson", forum.userId), {
        NotificationNumber: increment(1),
      })
        .then(() => {
          toast("Reply Send", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          updateDoc(doc(db, "Forum", id), {
            lastreply: moment().format(),
            replies: increment(1),
          });
          setName("");
          setFile();
          setTags("");
        });
    }
  };

  const addReplyAudio = async () => {
    if (chunksRef.current.length === 0) {
      console.log("No audio recorded.");
      return;
    }

    const audioBlob = new Blob(chunksRef.current, { type: "audio/mp3" });
    const audioRef = ref(storage, `forum-audios/${v4()}.mp3`);
    uploadBytes(audioRef, audioBlob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setAudioUrls((prev) => [...prev, url]);
        chunksRef.current = [];
        addDoc(collection(db, "Forum", id, "reply"), {
          tags,
          user: getUser?.name,
          email: getUser?.email,
          createdAt: moment().format(),
          profileimg: getUser?.profileimg || "",
          audioURL: url,
        });
        // addDoc(collection(db, "Forum", id, "reply"), {
        //   // profilePic: url.url,
        //   audioURL: url.url,
        // });
      });
    });

    addDoc(collection(db, "usersLesson", forum.userId, "Notification"), {
      Notification: `Someone Replied on Your Forum`,
      markasRead: false,
    });
    updateDoc(doc(db, "usersLesson", forum.userId), {
      NotificationNumber: increment(1),
    })
      .then(() => {
        toast("Reply Send", {
          duration: 2000,
          position: "top-center",
          icon: "✔",
          style: {
            borderRadius: "10px",
            background: "#3499EE",
            color: "#fff",
          },
        });
        closeModal();
      })
      .then(() => {
        updateDoc(doc(db, "Forum", id), {
          lastreply: moment().format(),
          replies: increment(1),
        });

        setFile();
        setTags("");
      });

    setRecentAudio("");
  };

  useEffect(() => {
    const q = query(
      collection(db, "Forum", id, "reply"),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForumData(cities);
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [audioUpload, setAudioUpload] = useState(null);
  const [audioUrls, setAudioUrls] = useState([]);

  const audiosListRef = ref(storage, "forum-audios/");

  // State to manage the recording status
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    // Check if the browser supports the MediaRecorder API
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log("MediaRecorder not supported in this browser.");
      return;
    }
  }, []);

  // Function to start recording
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.addEventListener("stop", handleStop);
        mediaRecorderRef.current.start();
        setRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
      });
  };

  // Function to stop recording
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    setRecording(false);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };
  const [recentaudio, setRecentAudio] = useState();


  const handleStop = () => {

    const audioBlob = new Blob(chunksRef.current, { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setRecentAudio(audioUrl);

    setAudioUrls((prev) => [...prev, audioUrl]);
  };


  useEffect(() => {
    listAll(audiosListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setAudioUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);
  const deleteAudio = (url) => {
    setAudioUrls((prev) => prev.filter((audio) => audio !== url));
    setRecentAudio("");
  };

  return (
    <>
      <div className="reply-bg">
        <div className="body-fix-h ">
          <div className="wrapper ">
            <div className="side-reply">
              <div className="sidebar-home-d">
                <Sidebar1 />
              </div>
              <div className="main-y  grid-c1 ">
                <div className="data-reply  grid-c2 ">
                  <div className="reply-data-s  ">
                    <div className="reply-main-data   ">
                      <div className="border-reply ">
                        <div>
                          <div className="head-reply-comment">
                            <div>
                              <button
                                onClick={() => navigate(-1)}
                                className="btn-head-comm"
                              >
                                Back
                              </button>
                              <h1 className="reply-h1">{forum?.name}</h1>
                              <div className="span-2-reply">
                                <span>Posted by</span>
                                <span className="name-hov"> {forum?.user}</span>
                                <span> on</span>
                                <span>
                                  {" "}
                                  {moment(forum?.createdAt).format(
                                    "MMMM Do YYYY , h:mm:ss a"
                                  )}
                                </span>
                              </div>
                              <div className="para-1-reply">
                                <p className="wrp">{forum?.description}</p>
                              </div>
                              {forum?.lastreply === "0" ? (
                                <></>
                              ) : (
                                <div className="span-2-reply">
                                  <span className="name-hov">Last Reply</span>
                                  <span>
                                    {" "}
                                    {moment(forum?.lastreply).fromNow()}
                                  </span>
                                </div>
                              )}
                              {forum?.Image ? (
                                <div className="forumimg">
                                  <img
                                    onClick={() => setOpen(true)}
                                    className="forumimgimg"
                                    src={forum?.Image}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />

                        <div className="reply-count reply-btn-count">
                          <h1>{forum?.replies} Replies</h1>
                          {/*  */}
                          <div className="btn-side">
                            {forum?.verified === "approved" ? (
                              <>
                                <div className="audio-section">
                                  {recentaudio ? (
                                    <></>
                                  ) : (
                                    <>
                                      {recording ? (
                                        <button onClick={stopRecording}>
                                          <div className="audio-r1">
                                            <AiFillPauseCircle size={20} />
                                            <h1>Audio Recording</h1>
                                          </div>
                                        </button>
                                      ) : (
                                        <>
                                          <MdKeyboardVoice
                                            onClick={startRecording}
                                            size={25}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {recentaudio && (
                                    <div className="audio-record">
                                      <div className="audio-record2">
                                        <audio autoPlay controls>
                                          <source
                                            src={recentaudio}
                                            type="audio/mp3"
                                          />
                                          {/* Your browser does not support the audio element. */}
                                        </audio>
                                        <MdDelete
                                          onClick={() =>
                                            deleteAudio(recentaudio)
                                          }
                                          size={20}
                                        />

                                        <RiSendPlane2Fill
                                          onClick={addReplyAudio}
                                          size={20}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <button
                                  onClick={openModal}
                                  className="btn-reply-s"
                                >
                                  Reply
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <hr />
                        {forumdata.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            {Array.isArray(forumdata)
                              ? forumdata.map((item, key) => {
                                  return (
                                    <>
                                      <div className="another-reply-1">
                                        <div className="user-dp-time-member">
                                          <div>
                                            {item?.profileimg === "" ? (
                                              <img
                                                className="user-reply-dp"
                                                src={require("../../Img/user.png")}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                className="user-reply-dp"
                                                src={item?.profileimg}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="user-data-t">
                                            <h1>{item?.user}</h1>
                                            <p>
                                              {moment(item?.createdAt).format(
                                                "MMMM Do YYYY , h:mm:ss a"
                                              )}
                                            </p>
                                          </div>
                                        </div>

                                        {item?.audioURL ? (
                                          <>
                                            <div className="section-reply-p2">
                                              <audio   controlsList="nodownload noplaybackrate"  controls>
                                                <source
                                                
                                                  src={item?.audioURL}
                                                  type="audio/mp3"

                                                />
                                           
                                              </audio>
{/* 
                                                <ReactAudioPlayer
                                                  className="react-audio-player1"
                                                  src={item?.audioURL}
                                                  type="audio/mpeg"
                                                  controls
                                                /> */}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="section-reply-p2">
                                              <p className="reply-para-uu wrp">
                                                {item?.reply}
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <hr />
                                    </>
                                  );
                                })
                              : null}
                          </>
                        )}
                      </div>
                      <div className="btn-side"></div>
                    </div>
                  </div>
                </div>

                <div className="grid-c3">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <div className="section1-reply">
              <div className="reply-headings">
                <h1 className="reply-h1-y">Reply to: {forum?.user}</h1>
              </div>

              <div className="svg-close-modal">
                <AiOutlineClose onClick={closeModal} />
              </div>
            </div>
            <hr />
            <div>
              <div className="section-2-reply">
                <div className="text-area-modal-s">
                  <textarea
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="section-2-replytext"
                    placeholder="Type your reply here"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="upload-options2">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
              </div>
              <hr />
              <div className="section-2-reply-1">
                <textarea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Type one or more tag, comma separated "
                  className="text-area-modal-2"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <hr />
              <div className="check-box-post-btn-s">
                <div>
                  <button
                    disabled={!name}
                    onClick={() => {
                      addReply();
                    }}
                    className="post-btn-dis"
                  >
                    Send <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                    className="post-btn-dis"
                  >
                    Cancel <MdCancel />
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: forum?.Image }]}
          />
        </div>
      </div>
    </>
  );
};

export default Replydata;
