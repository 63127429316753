import { doc, getDoc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { AuthContext } from "../../../../context/AuthContext";
import { db } from "../../../Firebase/Firebase";

const ModuleIconTick = ({ id, chapter }) => {
  const { user } = useContext(AuthContext);
  const [getUser, setGetUser] = useState([]);

  useEffect(() => {
    getDoc(
      doc(
        db,
        "usersLesson",
        user.uid,
        "MemberShip",
        "ContestPrepCertification",
        id,
        chapter
      )
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
      }
    });
  }, [chapter, id, user?.uid]);

  return (
    <>
      {getUser.completed === true ? (
        <div>
          <AiFillCheckCircle size={28} style={{ color: "#bc0000" }} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModuleIconTick;
