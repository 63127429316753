/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./CaseStudy.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Footer from "../Footer/Footer ";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";

const CaseStudy = () => {
  const navigate = useNavigate();

  const [casestudy, setCaseStudy] = useState();

  useEffect(() => {
    const q = query(collection(db, "CaseStudy"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setCaseStudy(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="case-c">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="grid-c1 main-y">
            <div className="section-case-study">
            <h1 className="cse-h-k">Case Studies</h1>
              <div className="sc-11">
                <div className="sc-12">
  {casestudy?.map((data) => (
    <>
       <section class="articles">
                    <article>
                      <div class="article-wrapper">
                        <figure>
                          <img src={data.Poster} alt="" />
                        </figure>
                        <div class="article-body">
                          <h2>{data.id}</h2>
                          <p>
                           {data.description}
                          </p>
                          <div
                            onClick={() =>
                              navigate(`/CaseStudyDate/${data.id}`)
                            }
                          >
                            <a class="read-more">
                              Read more
                              <span class="sr-only">
                                about this is some title
                              </span>
                              <BsArrowRight class="icon" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </section>
    </>
  ))}
                </div>
              </div>
            </div>

            <div className="grid-c3">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
