import React from "react";
import ReactPlayer from "react-player";
import "./Streadata.css";
import thum from "../../Img/vlog-thum.png";

const Streadata = ({ Blogs }) => {
  return (
    <div className="stream-comtainer">
      <div class="blog-card-v">
        <div className="blog-a3">
          <ReactPlayer
            className="i-f-dash-1 video-i-1 v-log-video-c"
            light={Blogs?.thumbnail}
            width="560"
            height="315"
            url={Blogs?.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            controls
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>
        <div class="card__content">
          <time datetime="2021-03-30" class="card__date">
            <p>{Blogs?.createdAt.toDate().toLocaleDateString()}</p>
          </time>
          <span class="card__title">{Blogs?.name}</span>
        </div>
      </div>
    </div>
  );
};

export default Streadata;
