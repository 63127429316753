/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useContext, useState } from "react";
import "./Modules.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer ";
import { useEffect } from "react";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";

import { AuthContext } from "../../../context/AuthContext";
import { MdPlayLesson } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { toast } from "react-hot-toast";
import Progress_bar from "../Home/ProgressBar";
import ModuleLessonBar from "./ModuleLessonBar/ModuleLessonBar";

const Modules = () => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
      setGetUser(doc.data());
    });

    return () => unsub();
  }, [user?.uid]);

  const[totalMod,setTotalMod] = useState(0);
  const[totalMods,setTotalMods] = useState('');
  const[totalLesson,setTotalLesson] = useState(0);


  useEffect(() => {
   

    getDoc(doc(db, "ContestPrepAcademy" , "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalMod(docSnap.data().TotalModule);
        setTotalMods(docSnap.data().TotalMod);
        setTotalLesson(docSnap.data().TotalLesson);
       
      }
    });
  }, [user?.uid]);

  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="bg-module">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="module-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y modules11 grid-c1">
              <div className="grid-c2">
                <div className="module-a1 ">
                  <div>
                    <div className="module-a2">
                      <div className="module-a3">
                        <h1 className="module-h1">
                          contest prep Certification
                        </h1>
                        <h1 className="module-h2">View Modules details </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="module-a4">
                  <div className="section-1-module">
                    <div className="module-a5">
                      {/*  */}
                      <div>
                        <div className="module-a6">
                          <div>
                            <h1 className="module-h3">
                            {(((parseInt(getUser?.Module1) +
                                parseInt(getUser?.Module2)) /
                                totalMod) *
                                100).toFixed(2)}%
                              Complete
                            </h1>
                            <h1 className="module-h4">
                              Last activity on 1 April 2022
                            </h1>
                          </div>
                          <Progress_bar
                            bgcolor="#bc0000"
                            progress={(
                              ((parseInt(getUser?.Module1) +
                                parseInt(getUser?.Module2)) /
                                totalMod) *
                              100
                            ).toFixed(2)}
                            height={6}
                          />
                        </div>
                      </div>
                      {/*  */}

                      {/*  */}
                      <div>
                        {/* Module 1 */}
                        <div>
                          {module?.map((item, index) => (
                            <>
                              <div key={item.id}>
                                <div>
                                  <h1
                                    style={{ textTransform: "uppercase" }}
                                    className="module-h6"
                                  >
                                    {item.Module} - {item.NAME}
                                  </h1>
                                </div>
                                <div className="modules-title">
                                  <ModuleLessonBar item={item} user={getUser} />
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="module-a020">
                      <div className="module-a20">
                        <div class="card-SideMoudle">
                          <img
                            src={require("./../../Img/home2.png")}
                            alt="Image"
                          />
                          <div class="card-description">
                            <div className="card-description-d1">
                              <h1 className="module-h15">In Progress</h1>
                              <button className="btn-1-Modules">
                                Continue
                              </button>
                            </div>
                            <div className="card-description-d2">
                              <h1 className="dec-h1">{totalMods}</h1>
                              <div className="card-description-d3">
                                <h1 className="dec-h2">
                                  <span className="span-cer1">
                                    <MdPlayLesson size={25} />
                                    {totalLesson} Lessons
                                  </span>
                                </h1>
                                <h1 className="dec-h2">
                                  <span className="span-cer1">
                                    <GrCertificate size={25} />
                                    Certification
                                  </span>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-c3">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
