import React, {  useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Signup.css'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "../../Firebase/Firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";




const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   axios
  //   .get("https://www.contestprepacademy.com/python/data")
  //     .then((response) => {
  //       setData(response.data.MAC)
  //     })
  //     .catch((error) => console.error(error));
  // }, []);




  const registerWithEmailAndPassword = async () => {
    try {
      const res = await createUserWithEmailAndPassword(auth ,email, password);
      const user = res.user;
      await setDoc(doc(db, "users" , email.toLowerCase()), {
        uid: user.uid,
        name: name,
        email: email,
        role: 'user',
        loginPermission: false,
        // MAC: data,
        createdAt: serverTimestamp(),
        Module1 :0,
        profileimg: ''

      }).then(()=> {
        toast.success('Account Created Successfully', {
          duration: 4000,
          position: 'top-right'
        });
        navigate('/')
      })

      await setDoc(doc(db, "usersLesson" , user.uid), {
        uid: user.uid,
        name: name,
        email: email,
        role: 'user',
        loginPermission: false,
        createdAt: serverTimestamp(),
        Module1: 0,
        profileimg: ''

      }).then(()=> {
        setDoc(doc(db, "usersLesson" , user.uid , 'MemberShip' , 'ContestPrepCertification'), {
          MemberShip: 'Contest Prep Certification',
          Subscription: 'Lifetime',
          Active: true,
          createdAt: serverTimestamp(),
          Expires: 'Never',
          PaymentMethod: 'N/A',

        })
        signOut(auth)

      })
    } catch (err) {
      console.error(err);
      toast.error(err.message , {
        duration: 2000,
        position: 'top-right'
      });
    }
  };



  


  return (
    <div className='sig-bg'>
      <div className='log-form-p1'>
        <div className='logo-pp51'>
          <img src={require('../../Img/logo.png')} alt="" />
        </div>
        <h1 className='log-h11'>SIGN UP</h1>
        <div className='log-form'> 
          <div >
            <div className='log-fgg1'>
            <input  value={name} onChange={e => setName(e.target.value)} className='input-log1' type="name"
              placeholder='Enter your name'
              name="signupname" id="signupname" />
              <input value={email} style={{textTransform: 'capitalize'}} onChange={e => setEmail(e.target.value)} className='input-log1' type="email"
              placeholder='Email'
              name="signupemail" id="signupemail" />
          <div className="passworld-hide-show1">

<input
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  className="input-log"
  placeholder="Password"
  type={showPassword ? "text" : "password"}
  
  
  name="signuppassword"
  id="signuppassword"
  />
    <div
                  className="password-icon3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
  </div>
<button
         onClick={() => registerWithEmailAndPassword()}
          className="log-bb11">SIGN UP</button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup