import { onSnapshot } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./caseStudyDate.css";
import { collection, query } from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";
import { useState } from "react";
import Sidebar1 from "../../../Features/Sidebar/Sidebar1";
import Footer from "../../Footer/Footer ";
import { BsFillFileEarmarkRichtextFill } from "react-icons/bs";

const CaseStudyDate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [getdata, setGetData] = useState();

  useEffect(() => {
    const q = query(collection(db, `CaseStudy/${id}/${id}`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setGetData(cities);
      console.log(cities);
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  return (
    <div className="caseStudyDate">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="grid-c1 main-y">
            <div className="grid-c2  ">
              <div className="w-11">
                <div className="w-12">
                  <div className="head-date-sections">
                    <div className="wrp-date-container">
                      {getdata?.map((data) => (
<>
<div onClick={()=> navigate(`/CaseStudyDetails/${data.id}` , { state: { detail: id,} })
} className="head-date1">
                        <div className="head-date2">
                          <BsFillFileEarmarkRichtextFill size={25} />
                        </div>
                        <div className="head-date3">
                          <h1>{data.id}</h1>
                        </div>
                      </div>{" "}
</>
                      ))}
                 
                
                    
                    
               
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid-c3">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDate;
