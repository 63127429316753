/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "../../Features/Sidebar/Sidenav.scss";
import "./Studydetails.css";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { db } from "../../Firebase/Firebase";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { FiMenu } from "react-icons/fi";
import "../../Features/Sidebar/Sidebar.css";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";

import Footer from "../Footer/Footer ";
import { MdPlayLesson } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  increment,
  onSnapshot,
  collection,
  query,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../../context/AuthContext";
import Progress_bar from "../Home/ProgressBar";
import StudyDetailLessonBar from "./StudyDetailLessonBar/StudyDetailLessonBar";

// import "react-tabs/style/react-tabs.css";
const StyledOffCanvas = styled.aside`
  border-right: 1px solid;
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 200;
  color: black;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Studydetails = () => {
  const location = useLocation();

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const { user } = useContext(AuthContext);

  const [chapterEnglish, setChapterEnglish] = useState();

  const [markascomplete, setMarkascomplete] = useState(false);
  const [getUser, setGetUser] = useState();
  const[totalMod,setTotalMod] = useState(0);

  //  const [nav,setnav]= useState();
  //  const na = ()=>{
  //   setnav();
  //  }

  useEffect(() => {
    getDoc(
      doc(
        db,
        "ContestPrepAcademy",
        location.state.module,
        location.state.module,
        location.state.id
      )
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setChapterEnglish(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

    onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
      setGetUser(doc.data());
    });

    getDoc(doc(db, "ContestPrepAcademy" , "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotalMod(docSnap.data().TotalModule);
       
      }
    });

  }, []);





  useEffect(() => {
    const temp = chapterEnglish?.chapter;

    onSnapshot(
      doc(
        db,
        "usersLesson",
        user?.uid,
        "MemberShip",
        "ContestPrepCertification",
        location?.state.module,
        `${temp}`
      ),
      (doc) => {
        setMarkascomplete(doc.data().completed);
        console.log(doc.data());
      }
    );
  }, [chapterEnglish]);

  const onClickMarkAsComplete = async () => {
    const washingtonRef = doc(
      db,
      "usersLesson",
      user?.uid,
      "MemberShip",
      "ContestPrepCertification",
      location.state.module,
      chapterEnglish?.chapter
    );
    const washingtonRef1 = doc(db, "usersLesson", user?.uid);

    // Set the "capital" field of the city 'DC'
    await setDoc(washingtonRef, {
      completed: true,
    }).then(() => {
      updateDoc(washingtonRef1, {
        Module1: increment(25),
      }).then(() => {
        onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
          setGetUser(doc.data());
        });
      });

      toast.success("Marked as Completed");
    });
  };

  const [toggleState2, setToggleState2] = useState(1);
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", user?.uid), (doc) => {
      setGetUser(doc.data());
    });

    return () => unsub();
  }, [user?.uid]);

  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <div className="bg-study">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="fle-main-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y grid-c1">
              <div className="tabs-1 grid-c2 ">
                {/* <Tabs>
            <TabList> */}
                <div>
                  <StyledOffCanvas isOpen={isOpen}>
                    <div className="head-side-modules">
                      <div className="close-div-svg">
                        <AiOutlineClose
                          className="svg-side close-svg1"
                          onClick={() => setIsOpen(!isOpen)}
                        />
                      </div>
                      <div className="sidebar-a1">
                        <div className="head-h">
                          <img src={require("../../Img/logo.png")} alt="" />
                        </div>
                        <div className="progress-bar-a1">
                          {/* <div class="progress-Modules">
                            <div class="progress-value-Modules"></div>
                          </div> */}
                          <Progress_bar
                            bgcolor="#bc0000"
                            progress={(
                              ((parseInt(getUser?.Module1)) /
                                totalMod) *
                              100
                            ).toFixed(2)}
                            height={6}
                          />
                          <div className="side-mod3">
                            <div>
                              <h1 className="side-mod1">
                                {(
                                  ((parseInt(getUser?.Module1)) /
                                    totalMod) *
                                  100
                                ).toFixed(2)}
                                % Complete
                              </h1>
                            </div>
                            <div>
                              <h1 className="side-mod2">
                                {chapterEnglish?.chapter} /{" "}
                                {location.state.module === "Module1"
                                  ? "4"
                                  : "6"}
                              </h1>
                            </div>
                          </div>
                          <hr />
                          <div className="module-title-2">
                            {module?.map((item, index) => (
                              <div className="module-side-a2">
                                <div>
                                  <h1
                                    style={{ textTransform: "uppercase" }}
                                    className="side-m-h1"
                                  >
                                    {item.Module} - {item.NAME}
                                  </h1>
                                </div>
                                <StudyDetailLessonBar
                                  item={item}
                                  user={getUser}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyledOffCanvas>
                  <StyledOffCanvasOverlay
                    isOpen={isOpen}
                    onClick={() => setIsOpen(false)}
                  />
                </div>

                {/* </TabList> */}
                <div className="menu-modules">
                  <FiMenu
                    className="svg-open svg-side"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                {/* <TabPanel> */}
                <div>
                  <div className="paf-g tab-panel-i">
                    <div className="tab-oi">
                      <div>
                        <h1
                          className="intro-o"
                          style={{ textTransform: "uppercase" }}
                        >
                          {location.state.NAME} - {chapterEnglish?.name}
                        </h1>
                      </div>
                      <div className="mod-a1">
                        <h1 className="module-ah1">
                          LESSON {chapterEnglish?.chapter} OF{" "}
                          {location.state.module === "Module1" ? "4" : "6"}
                        </h1>
                        <div className="mod-a2">
                          {markascomplete === true ? (
                            <span className="span-mod1">Completed</span>
                          ) : (
                            <span
                              onClick={() => onClickMarkAsComplete()}
                              className="span-mod2"
                            >
                              Mark as Complete
                            </span>
                          )}
                        </div>
                      </div>
                      <div>
                        <div>
                          <Tabs>
                            <div>
                              <TabList className="tab-list-modules">
                                <Tab
                                  onClick={() => toggleTab(1)}
                                  className={toggleState === 1 ? "a1" : "a2"}
                                >
                                  <span className="span-m1">
                                    <MdPlayLesson size={25} />
                                    Lesson
                                  </span>
                                </Tab>
                                <Tab
                                  onClick={() => toggleTab(2)}
                                  className={toggleState === 2 ? "a1" : "a2"}
                                >
                                  <span className="span-m2">
                                    <TbListDetails size={25} />
                                    Materials
                                  </span>
                                </Tab>
                              </TabList>
                              <hr />
                            </div>
                            <div>
                              <TabPanel className="tab-panel-m1">
                                <Tabs>
                                  <TabList>
                                    <div
                                      style={{ marginBottom: "30px" }}
                                      className="dash-7"
                                    >
                                      <Tab>
                                        <button
                                          onClick={() => toggleTab2(1)}
                                          className={
                                            toggleState2 === 1
                                              ? "dash-btn-1"
                                              : "dash-btn1"
                                          }
                                        >
                                          English
                                        </button>
                                      </Tab>
                                      <Tab>
                                        <button
                                          onClick={() => toggleTab2(2)}
                                          className={
                                            toggleState2 === 2
                                              ? "dash-btn-2"
                                              : "dash-btn2"
                                          }
                                        >
                                          Hindi
                                        </button>
                                      </Tab>
                                    </div>
                                  </TabList>
                                  {/* Intro Video English */}

                                  <TabPanel>
                                    <iframe
                                      src={chapterEnglish?.Eurl}
                                      style={{
                                        border: 0,
                                        height: "360px",
                                        width: "640px",
                                        maxWidth: "100%",
                                        borderRadius: "15px",
                                      }}
                                      allowFullScreen="true"
                                      allow="encrypted-media"
                                      title="ContestPrep"
                                    ></iframe>
                                  </TabPanel>
                                  {/* Intro Video Hindi */}

                                  <TabPanel>
                                    <iframe
                                      src={chapterEnglish?.Hurl}
                                      style={{
                                        border: 0,
                                        height: "360px",
                                        width: "640px",
                                        maxWidth: "100%",
                                        borderRadius: "15px",
                                      }}
                                      allowFullScreen="true"
                                      allow="encrypted-media"
                                      title="ContestPrep"
                                    ></iframe>
                                  </TabPanel>
                                </Tabs>
                                <div className="i-a3"></div>
                              </TabPanel>
                            </div>
                            <div className="tab-panel-2">
                              <TabPanel>
                                <div className="MaterialsLesson">
                                  <p>Click Below to Open the Desired PDF</p>
                                  {chapterEnglish?.PDF ? (
                                    <a
                                      target="_blank"
                                      style={{ marginTop: "25px" }}
                                      className="button-24"
                                      href={chapterEnglish?.PDF}
                                    >
                                      Download PDF
                                    </a>
                                  ) : (
                                    <p>PDF will uploaded soon!</p>
                                  )}
                                </div>
                              </TabPanel>
                            </div>
                          </Tabs>
                        </div>
                      </div>

                      <div className="lessons-b1">
                        <div className="lessons-a1">
                          <h1>HAVE A QUESTION ON THE MATERIAL:</h1>
                        </div>
                        <div className="lessons-a2">
                          <h1>
                            Head to the{" "}
                            <span
                              onClick={() => navigate("/Forums")}
                              className="span-1-lessons"
                            >
                              forums{" "}
                            </span>
                          </h1>
                        </div>
                        {chapterEnglish?.BonusVideo ? (
                          <div className="BonusVideo">
                            <h1>Bonus Video</h1>
                            <iframe
                              src={chapterEnglish?.BonusVideo}
                              style={{
                                border: 0,
                                height: "360px",
                                width: "640px",
                                maxWidth: "100%",
                                borderRadius: "15px",
                              }}
                              allowFullScreen="true"
                              allow="encrypted-media"
                              title="ContestPrep"
                            ></iframe>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* </TabPanel> */}
                {/* </Tabs> */}

                {/* <ChatApp /> */}
              </div>

              <div className="grid-c3">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Studydetails;
