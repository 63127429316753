import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useState, useRef } from "react";
import "./Contact.css";
import { toast } from "react-hot-toast";
import Sidebar2 from "../../Features/Sidebar/Sidebar2";

import emailjs from "@emailjs/browser";
import { db } from "../../Firebase/Firebase";
import Footer2 from "../Footer/Footer2";
const Contact2 = () => {
  const form = useRef();

  const [name, setName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8zxl673",
        "template_t2nlr9f",
        form.current,
        "mGFKpa6Q60RYTwD8D"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    addDoc(collection(db, "ContactUs"), {
      name,
      phone,
      email,
      message,
      createdAt: serverTimestamp(),
    }).then(() => {
      toast.success("Sended Successfully");
      setName("");
      setemail("");
      setphone("");
      setmessage("");
    });
  };

  return (
    <div className="bg-contact">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar2 />
          </div>
          <div className="grid-c1 main-y">
            <div className="contact-section1 grid-c2  ">
              <div className="head-pp ">
                <div className="contact-section2">
                  {/* <h1 className="contact-h1">Feel Free To Contact Us! </h1> */}
                  <div style={{ textAlign: "center" }}>
                    {/* 
<h1 className="contact-h2">+91 9876543210 </h1>
<h1 className="contact-h3">example@gmail.com</h1> */}
                  </div>
                </div>

                <div className="contact-us-form">
                  <div class="login-box">
                    <h2>Contact Us</h2>

                    <form ref={form} onSubmit={sendEmail} autocomplete="on">
                      <div class="user-box">
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          name="name"
                          autocomplete="off"
                          required
                        />
                        <label>Name</label>
                      </div>
                      <div class="user-box">
                        <input
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          type="text"
                          name="email"
                          autocomplete="off"
                          required
                        />
                        <label>Email</label>
                      </div>
                      <div class="user-box">
                        <input
                          value={phone}
                          onChange={(e) => setphone(e.target.value)}
                          type="text"
                          name="contact-no"
                          required
                        />
                        <label>Contact No</label>
                      </div>
                      <div class="user-box">
                        <textarea
                          value={message}
                          onChange={(e) => setmessage(e.target.value)}
                          name="message"
                          id=""
                          placeholder="Write Your Message Here"
                          required
                        ></textarea>
                      </div>
                      <div className="div-btn-sub">
                        <button
                          disabled={!name || !email || !phone || !message}
                          type="submit"
                          className="submit-btn-contact"
                          value="Send"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-c3">
              <Footer2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact2;
