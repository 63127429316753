import React, { useState } from "react";
import {
  SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SLogo,

  SSidebar,
  SSidebarButton,

} from "./styles";
import "./Side.css";


import {

  AiOutlineHome,

} from "react-icons/ai";
import {  BsLayoutSplit } from "react-icons/bs";

import {  FaBookmark } from "react-icons/fa";

import { TbListDetails } from "react-icons/tb";




// import { ThemeContext } from "./../../App";
import { useLocation } from "react-router-dom";
import { RiContactsBookUploadFill } from "react-icons/ri";

const Sidebar2 = () => {
 

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();



  return (
    <SSidebar isOpen={sidebarOpen}>
      <>
        <SSidebarButton
          isOpen={sidebarOpen}
          onClick={() => setSidebarOpen((p) => !p)}
        >
          <BsLayoutSplit />
        </SSidebarButton>
      </>
      <SLogo>{/* <img src={logoSVG} alt="logo" /> */}</SLogo>
  
      {linksArray.map(({ icon, label, notification, to }) => (
<div class={ "tooltip" }>
<SLinkContainer  key={label} isActive={pathname === to}>
          
          <SLink to={to} style={!sidebarOpen ? { width: `fit-content` } : {}}>

            
          <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>
            {sidebarOpen && (
              <>

              
                <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
                {/* if notifications are at 0 or null, do not display */}
                {!!notification && (
                  <SLinkNotification>{notification}</SLinkNotification>
                )}
              </>
            )}
          </SLink>
        </SLinkContainer>
            {/* <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>  */}
            {sidebarOpen ? "" :

<div class={"right"}>
<div class="text-content">

  {sidebarOpen? 
      "" :   <h3 style={{ whiteSpace: "pre" }}>
      <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
    </h3>
      
    }
</div>
<i></i>
</div>
}
</div>
))}
      <SDivider />
      {secondaryLinksArray.map(({ icon, label }) => (
        <SLinkContainer key={label}>
          <SLink to={'/'} style={!sidebarOpen ? { width: `fit-content` } : {}}>
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && <SLinkLabel>{label}</SLinkLabel>}
          </SLink>
        </SLinkContainer>
      ))}
      <SDivider />
 
    </SSidebar>
  );
};

const linksArray = [

  {
    label: "Home",
    icon: <AiOutlineHome/>,
    to: "/",
    notification: 0,
  },

  {
    label: "COURSE DETAILS",
    icon: <TbListDetails />,
    to: "/Course-details",
    notification: 0,
  },
  {
    label: "ABOUT US",
    icon: <FaBookmark />,
    to: "/AboutUs2",
    notification: 0,
  },

  {
    label: "CONTACT US",
    icon: <RiContactsBookUploadFill/>,
    to: "/Contact2",
    notification: 0,
  },


];
const secondaryLinksArray = [

 
];

export default Sidebar2;
