import React, { useState } from "react";
import "./CaseStudyDetails.css";

import { useLocation, useParams } from "react-router-dom";
import Sidebar from "../../../Features/Sidebar/Sidebar1";
import Footer from "../../Footer/Footer ";

import { useEffect } from "react";
import { collection, doc, getDoc, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../Firebase/Firebase";

const CaseStudyDetails = () => {
  const { state } = useLocation();
  const { detail } = state;

  const { id } = useParams();

  const [getdata, setGetData] = useState();
  const[video,setVideo] = useState('')

  useEffect(() => {
    const q = query(
      collection(db, `CaseStudy/${detail}/${detail}/${id}/Image`),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setGetData(cities);
    });

    return () => {
      unsubscribe();
    };
  }, [detail, id]);

  useEffect(() => {

  

getDoc(doc(db, `CaseStudy/${detail}/${detail}/${id}/Video/Video`)).then(docSnap => {
    if (docSnap.exists()) {
      setVideo({id: docSnap.id, ...docSnap.data()});
    } else {
     setVideo(null)
    }
  })





  
  }, [detail, id]);

  return (
    <div className="case-c">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar />
          </div>
          <div className="grid-c1 main-y">
            <div className="section1-im1 grid-c2  ">
          
              <div className="swiper-ddd">
           {video === null ? (
 <>
 </>
           ) : (
<>
<div className="VideoPlayer">
     <h4 style={{marginBottom: '20px' , textAlign: 'center'}}>Case Study Video</h4>
   <iframe
                         src={video?.url}
                         style={{
                           border: 0,
                           height: "360px",
                           width: "640px",
                           maxWidth: "100%",
                           borderRadius: "15px",
                         }}
                         allowFullScreen="true"
                         allow="encrypted-media"
                         title="ContestPrep"
                       ></iframe>
   </div>
</>
           )}
                <h4 style={{marginBottom: '20px'}}>Case Study Images</h4>
                {getdata?.map((doc) => (
                  <img className="case-img-s" src={doc.img} alt="" />
                ))}
              </div>
            </div>

            <div className="grid-c3">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetails;
