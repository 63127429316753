import { createBrowserRouter } from "react-router-dom";
import ChatApp from "../../Components/Features/ChatApp/ChatApp";
import Logout123 from "../../Components/Features/LOGOUT/logout";
import Aboutus from "../../Components/Pages/Aboutus/Aboutus";
import Aboutus2 from "../../Components/Pages/Aboutus/Aboutus2";
import Blog from "../../Components/Pages/Blog/Blog";
import Contact from "../../Components/Pages/Contact/Contact";
import Contact2 from "../../Components/Pages/Contact/Contact2";
import Course from "../../Components/Pages/Course/Course";
import Course2 from "../../Components/Pages/Course/Course2";
import Dashboard from "../../Components/Pages/Dashboard/Dashboard";
import Forums from "../../Components/Pages/Forum/Forums";
import Replydata from "../../Components/Pages/Forum/Replydata";
import Home from "../../Components/Pages/Home/Home";
import LiveS from "../../Components/Pages/LiveStream/LiveS";
import Login from "../../Components/Pages/Login/Login";
import Verification from "../../Components/Pages/Login/Verification";
import PrivacyPolicy from "../../Components/Pages/Policy-Term/PrivacyPolicy";
import PrivacyPolicy2 from "../../Components/Pages/Policy-Term/PrivacyPolicy2";
import Term from "../../Components/Pages/Policy-Term/Term";
import Term2 from "../../Components/Pages/Policy-Term/Term2";
import Profile from "../../Components/Pages/Profile/Profile";
import CaseStudyDate from "../../Components/Pages/CaseStudy/caseStudyDate/caseStudyDate";
import CaseStudy from "../../Components/Pages/CaseStudy/CaseStudy";
import CaseStudyDetails from "../../Components/Pages/CaseStudy/caseStudyDetails/CaseStudyDetails";
import Signup from "../../Components/Pages/Signup/Signup";
import Modules from "../../Components/Pages/Studydetails/Modules";
import Studydetails from "../../Components/Pages/Studydetails/Studydetails";
import UserPlan from "../../Components/Pages/UserPlan/UserPlan";
import Main from "../../layout/Main";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Test from "../../Components/Pages/Test";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Dashboard></Dashboard>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/SignUp",
        element: <Signup></Signup>,
      },    {
        path: "/Course-details",
        element: <Course2></Course2>,
      },
      {
        path: "/AboutUs2",
        element: <Aboutus2></Aboutus2>,
      },
      {
        path: "/Terms_Condition",
        element: <Term2></Term2>,
      },
      {
        path: "/Privacy_Policy",
        element: <PrivacyPolicy2></PrivacyPolicy2>,
      },
      {
        path: "/Contact2",
        element: <Contact2></Contact2>,
      },
      {
        path: "/logout",
        element: <Logout123></Logout123>,
      },
      {
        path: "/loginVerification",
        element: <Verification></Verification>,
      },
      

      {
        path: "/Home",
        element: (
          <PrivateRoute>
            <Home></Home>
          </PrivateRoute>
        ),
      },
      {
        path: "/Modules/Lessons",
        element: (
          <PrivateRoute>
            <Studydetails></Studydetails>
          </PrivateRoute>
        ),
      },

      {
        path: "/Chat",
        element: (
          <PrivateRoute>
            <ChatApp></ChatApp>
          </PrivateRoute>
        ),
      },
      {
        path: "/Forums",
        element: (
          <PrivateRoute>
            <Forums></Forums>
          </PrivateRoute>
        ),
      },
      {
        path: "/Reply/:id",
        element: (
          <PrivateRoute>
            <Replydata></Replydata>
          </PrivateRoute>
        ),
      },
      {
        path: "/AboutUs",
        element: (
          <PrivateRoute>
            <Aboutus></Aboutus>
          </PrivateRoute>
        ),
      },

      {
        path: "/User",
        element: (
          <PrivateRoute>
            <Profile></Profile>
          </PrivateRoute>
        ),
      },
      {
        path: "/UserPlan",
        element: (
          <PrivateRoute>
            <UserPlan></UserPlan>
          </PrivateRoute>
        ),
      },   {
        path: "/Course",
        element: (
          <PrivateRoute>
            <Course></Course>
          </PrivateRoute>
        ),
      },
      {
        path: "/Test",
        element: (
          <PrivateRoute>
            <Test></Test>
          </PrivateRoute>
        ),
      },
      {
        path: "/Terms-Condition",
        element: (
          <PrivateRoute>
            <Term></Term>
          </PrivateRoute>
        ),
      },   {
        path: "/Privacy-Policy",
        element: (
          <PrivateRoute>
            <PrivacyPolicy></PrivacyPolicy>
          </PrivateRoute>
        ),
      },
      {
        path: "/CaseStudy",
        element: (
          <PrivateRoute>
            <CaseStudy></CaseStudy>
          </PrivateRoute>
        ),
      }, 
      
      {
        path: "/CaseStudyDate/:id",
        element: (
          <PrivateRoute>
            <CaseStudyDate></CaseStudyDate>
          </PrivateRoute>
        ),
      },


      {
        path: "/CaseStudyDetails/:id",
        element: (
          <PrivateRoute>
            <CaseStudyDetails></CaseStudyDetails>
          </PrivateRoute>
        ),
      },
      
      {
        path: "/Modules",
        element: (
          <PrivateRoute>
            <Modules></Modules>
          </PrivateRoute>
        ),
      },
      {
        path: "/LiveStream",
        element: (
          <PrivateRoute>
            <LiveS></LiveS>
          </PrivateRoute>
        ),
      },
      
      
      {
        path: "/Vlog",
        element: (
           <PrivateRoute>
            <Blog></Blog>
          </PrivateRoute>
        ),
      },
    ],
  },
]);
