import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { db } from "../../../Firebase/Firebase";
import "../Modules.css";
import ModuleIconTick from "./ModuleIconTick";

const ModuleLessonBar = ({ item }) => {
  const navigate = useNavigate();

  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy", item?.id, item?.id);
    const q = query(todosref, orderBy("chapter", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, [item?.id]);

  return (
    <div className="modules-title">
      {module?.map((data, index) => (
        <div
          key={index}
          onClick={() =>
            navigate("/Modules/Lessons", {
              state: { id: data.id, module: item.id , NAME: item.Module},
            })
          }
          className="module-a14"
        >
          <div>
            <h1 className="module-h8">{data.name}</h1>
          </div>
          <ModuleIconTick id={item.id} chapter={data.chapter} />
        </div>
      ))}
    </div>
  );
};

export default ModuleLessonBar;
