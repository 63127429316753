/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext, useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { BsCameraVideoFill, BsChatSquareText } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { FaBookmark, FaUserCircle } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { TbListDetails } from "react-icons/tb";

import { HiUserCircle } from "react-icons/hi";
import { AiFillCalendar, AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";

import { IoIosArrowDown, IoMdContacts } from "react-icons/io";

import { BiMessageAltDetail } from "react-icons/bi";
import { MdLogout, MdNotifications } from "react-icons/md";
import styled from "styled-components";
import { toast } from "react-hot-toast";

import { db } from "../../Firebase/Firebase";
import {
  doc,
  onSnapshot,
  query,
  collection,
  updateDoc,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { AuthContext } from "../../../context/AuthContext";
import { RxCross1 } from "react-icons/rx";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: #bc0000;
  z-index: 1;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  // const [user, loading] = useAuthState(auth);
  const { user, userLogout } = useContext(AuthContext);

  const [getUser, setGetUser] = useState();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", user.uid), (doc) => {
      setGetUser({ id: doc.id, ...doc.data() });
    });

    return () => unsub();
  }, []);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  let activeStyle = {
    // textDecoration: "underline",
    borderBottom: "2px solid white",
    // padding: "2px",
    // textunderlineOffset: '8px',
  };

  const handleLogout = () => {
    userLogout()
      .then((result) => {
        toast.success("Successfully Logout!");
      })
      .catch((error) => console.error(error));
  };

  const [getnotifi, setgetnotifi] = useState();

  useEffect(() => {
    const q = query(collection(db, "usersLesson", user.uid, "Notification"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setgetnotifi(cities);
    });

    return () => unsubscribe();
  }, [user.uid]);

  const markasreadnoti = async (id) => {
    updateDoc(doc(db, "usersLesson", user.uid), {
      NotificationNumber: increment(-1),
    }).then(() => {
      deleteDoc(doc(db, "usersLesson", user.uid, "Notification", id));
    });
  };

  const ref = useRef(null);

  const opp = () => {
    setop(!op);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [op, setop] = useState(false);
  // const opp = () => {
  //   setop(!op);
  // };
  const opp2 = () => {
    setop(false);
  };

  return (
    <div className="nav-bg">
      <div className="fix-pro-nav">
        <div className="fle1-nav">
          <div className="logo-1">
            <img
              onClick={() => navigate("/Test")}
              className="nav-logo"
              src={require("../../Img/logo.png")}
              alt=""
            />
          </div>

          <div className="nav-bb">
            <div className="bodys">
              <div ref={ref}>
                <div className="dropdown">
                  <span onClick={opp}>
                    <a className="button">
                      <span className="user-n1">
                        {getUser?.name}
                        <IoIosArrowDown />
                      </span>

                      <FaUserCircle style={{ fontSize: "30px" }} />
                    </a>
                  </span>

                  {op && (
                    <ul>
                      <li>
                        <span onClick={opp2}>
                          <Link className="gap-svg-h" to={"/User"}>
                            <div>
                              <CgProfile />
                            </div>
                            {getUser?.nickname ? (
                              <span>@{getUser?.nickname}</span>
                            ) : (
                              <span>{getUser?.name}</span>
                            )}
                          </Link>
                        </span>
                      </li>
                      <li className="gap-sv">
                        <span onClick={opp2}>
                          <Link className="gap-svg-h" to={"/UserPlan"}>
                            <AiFillCalendar />
                            My Plans
                          </Link>
                        </span>
                      </li>

                      <li className="gap-sv">
                        <span onClick={opp2}>
                          <Link className="gap-svg-h" to={"/Forums"}>
                            <BiMessageAltDetail />
                            Forums
                          </Link>
                        </span>
                      </li>
                      <li className="gap-sv">
                        <Link
                          onClick={() => handleLogout()}
                          className="gap-svg-h"
                        >
                          <MdLogout />
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>

            {/*Notification */}
            <div className="bodys">
              <details
                className={
                  getnotifi?.length === 0 ?
                    "dropdown1"
                    : "dropdown1 ho-ull "
                }
              >
              
                <summary role="button">
                  {getUser?.NotificationNumber === 0 ? (
                    <a className="button">
                      <MdNotifications style={{ fontSize: "30px" }} />
                    </a>
                  ) : (
                    <a className="button">
                      <MdNotifications style={{ fontSize: "30px" }} />
                      <span 
                         className={
                          getnotifi?.length === 0
                            ? ""
                            : "batchnumbernotification"
                        }
                      >
                        {getUser?.NotificationNumber}
                      </span>
                    </a>
                  )}
                </summary>
                <ul>
                  {getnotifi?.length === 0 ? (
                    <>
                      <li className="gap-sv">
                        <div className="gap-svg-h1">No Notification</div>
                      </li>
                    </>
                  ) : (
                    <>
                      {Array.isArray(getnotifi)
                        ? getnotifi.map((item, key) => {
                            return (
                              <li className="gap-sv">
                                <div className="gap-svg-h1">
                                  {item.Notification}
                                  <RxCross1
                                    className="markasrednotifi"
                                    onClick={() => markasreadnoti(item.id)}
                                    size={30}
                                  />
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </>
                  )}

                  {/* <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Forum Panel is added Successfully
                      <RxCross1 size={30} />
                    </Link>
                  </li>

                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Your Forum's Approval is Pending
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Your Forum request is Approved
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Your Forum request is Declined
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Someone Replied on Your Forum
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Lecture update is Available , Click Here to view
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      A new Video is Added to Vlog , Click Here to View
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      Next Live is announced , Click Here to view Timmings
                      <RxCross1 size={30} />
                    </Link>
                  </li>
                  <li className="gap-sv">
                    <Link className="gap-svg-h">
                      New Case Study Updated , Click Here to view
                      <RxCross1 size={30} />
                    </Link>
                  </li> */}
                </ul>
              </details>
            </div>

            {/* mobile-menu */}
            <div className="mobile-menu-nav">
              <button onClick={() => setIsOpen(!isOpen)}>
                <GrMenu className="open-tab-mob" />
              </button>
              <StyledOffCanvas isOpen={isOpen}>
                <div className="head-mobile-menu">
                  <ul className="ul-menu-mobile">
                    <li>
                      <div className="mobile-k-m">
                        <Link to="/User">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            <div className="user-m">
                              <div>
                                <HiUserCircle size={40} />
                              </div>
                              <div>
                                <h1 className="nav-h-1"> {getUser?.name}</h1>
                              </div>
                            </div>
                          </span>
                        </Link>

                        <div>
                          <a onClick={() => setIsOpen(false)}>
                            <AiOutlineClose className="close-mobile-menu" />
                          </a>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="side-menu-h1">
                        <AiOutlineHome />
                        <Link to="/Home">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            HOME
                          </span>
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="side-menu-h1">
                        <ImBooks />
                        <Link to="/Modules">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            STUDY DETAILS
                          </span>
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="side-menu-h1">
                        <BsChatSquareText />
                        <Link to="/Forums">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            FORUMS
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <CiStreamOn />
                        <Link to="/LiveStream">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            LIVE STREAM
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <TbListDetails />
                        <Link to="/Course">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            COURSE DETAILS
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <FaBookmark />
                        <Link to="/Aboutus">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            ABOUT US
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <BsCameraVideoFill />
                        <Link to="/Vlog">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            Vlog
                          </span>
                        </Link>
                      </div>
                    </li>
                    {/* <li>
                      <div className="side-menu-h1">
                      <BsFillCameraVideoFill/>
                        <Link to="/Broadcast">
                          <span 
                          onClick={() => setIsOpen(false)}
                          className="link-k">Broadcast</span>
                        </Link>
                      </div>


                    </li> */}
                    <li>
                      <div className="side-menu-h1">
                        <IoMdContacts />
                        <Link to="/CaseStudy">
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            Case Studies
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="side-menu-h1">
                        <MdLogout />
                        <Link onClick={() => handleLogout()}>
                          <span
                            onClick={() => setIsOpen(false)}
                            className="link-k"
                          >
                            Log out
                          </span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </StyledOffCanvas>
              <StyledOffCanvasOverlay
                isOpen={isOpen}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className="ul-n">
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to={"/Home"}
                className="nav-a"
              >
                Home
              </NavLink>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Navbar;
