import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { auth, db, logInWithEmailAndPassword } from "../../Firebase/Firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { getDoc, doc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import Fingerprint2 from "fingerprintjs2";
import sha256 from "crypto-js/sha256";
import { useEffect } from "react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("https://www.contestprepacademy.com/python/data")
  //     .then((response) => {
  //       setData(response.data.MAC);
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // const firstClick = async() => {
  //   getDoc(doc(db, "users", email.toLowerCase())).then((docSnap) => {
  //     if (docSnap.exists()) {
  //       if (docSnap.data().loginPermission === true) {
  //         if (docSnap.data().MAC === data) {
  //           logInWithEmailAndPassword(email, password)
  //             .then((result) => {
  //               const user = result.user;
  //               if (user) {
  //                 toast.success("Successfully Login!");
  //                 navigate(from, { replace: true });
  //               }
  //             })
  //             .catch((error) => toast.error(error));
  //         } else {
  //           navigate("/");
  //           toast.error("Login with registered device", {
  //             position: "top-right",
  //           });
  //         }
  //       } else {
  //         navigate("/");
  //         toast.error("Payment is not completed yet", {
  //           position: "top-right",
  //         });
  //       }
  //     } else {
  //       toast.error("No Account Registered", {
  //         position: "top-right",
  //       });
  //       navigate("/");
  //     }
  //   });
  // }

  // const location = useLocation();

  // const from = location.state?.from?.pathname || "/";

  // const secretKey = `${user.uid}`;
  const [device_id, setDevice_id] = useState("");
  async function generateDeviceId(uid) {
    const components = await Fingerprint2.getPromise();
    const values = components.map((component) => component.value);
    const fingerprint = sha256(values.join("")).toString();
    const deviceId = sha256(`${fingerprint}:${uid}`).toString();
    setDevice_id(deviceId);
    console.log(device_id);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    // userLogin(email, password)
    //   .then((result) => {
    //     const user = result.user;
    //     if (user) {
    //       toast.success("Successfully Login!");
    //       navigate(from, { replace: true });
    //     }
    //   })
    //   .catch((error) => toast.error(error));

    getDoc(doc(db, "users", email.toLowerCase())).then((docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().loginPermission === true) {
          // generateDeviceId()
          
          
          // async function generateDeviceId() {
          //   const components = await Fingerprint2.getPromise();
          //   const values = components.map((component) => component.value);
          //   const fingerprint = sha256(values.join("")).toString();
          //   const deviceId = sha256(`${fingerprint}:${docSnap.data().uid}`).toString();

          //   if (docSnap.data().device_id === `${deviceId}`) {
              try {
                signInWithEmailAndPassword(auth, email, password).then(() => {
                  toast.success("Login Successfully.");
                  navigate("/loginVerification", { replace: true });
                });
              } catch (error) {
                alert(error);
              }
          //   } else {
          //     toast.error("Your device is not registered");
          //   }
          // }

        } else {
          navigate("/");
          toast.error("Account is not verified by Admin", {
            position: "top-right",
          });
        }
      } else {
        toast.error("No Account Registered", {
          position: "top-right",
        });
        navigate("/");
      }
    });
  };



  return (
    <div className="log-bg">
      <div className="log-form-p">
        <div className="logo-pp5">
          <img src={require("../../Img/logo.png")} alt="" />
        </div>
        <h1 className="log-h1">LOG IN</h1>
        <div className="log-form">
          <form onSubmit={handleLogin}>
            <div className="log-fgg">
              <input
                required
                className="input-log"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                name="email"
                id="email"
              />
              <div className="passworld-hide-show">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-log"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                />
                <div
                  className="password-icon2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>

              <button type="submit" className="log-bb1">
                LOG IN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
