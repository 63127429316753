// // // import { useState, useEffect, useRef } from "react";
// // // import {
// // //   ref,
// // //   uploadBytes,
// // //   getDownloadURL,
// // //   listAll,
// // // } from "firebase/storage";

// // // import { v4 } from "uuid";
// // // import { storage } from "../Firebase/Firebase";
// // // import ReactAudioPlayer from "react-audio-player";

// // // function Test() {
// // //   const [audioUpload, setAudioUpload] = useState(null);
// // //   const [audioUrls, setAudioUrls] = useState([]);

// // //   const audiosListRef = ref(storage, "audios/");

// // //   // State to manage the recording status
// // //   const [recording, setRecording] = useState(false);
// // //   const mediaRecorderRef = useRef(null);
// // //   const chunksRef = useRef([]);

// // //   useEffect(() => {
// // //     // Check if the browser supports the MediaRecorder API
// // //     if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
// // //       console.log("MediaRecorder not supported in this browser.");
// // //       return;
// // //     }
// // //   }, []);

// // //   // Function to start recording
// // //   const startRecording = () => {
// // //     navigator.mediaDevices
// // //       .getUserMedia({ audio: true })
// // //       .then((stream) => {
// // //         mediaRecorderRef.current = new MediaRecorder(stream);
// // //         mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
// // //         mediaRecorderRef.current.start();
// // //         setRecording(true);
// // //       })
// // //       .catch((error) => {
// // //         console.error("Error accessing the microphone:", error);
// // //       });
// // //   };

// // //   // Function to stop recording
// // //   const stopRecording = () => {
// // //     if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
// // //       mediaRecorderRef.current.stop();
// // //     }
// // //     setRecording(false);
// // //   };

// // //   // Event listener to handle data available from the MediaRecorder
// // //   const handleDataAvailable = (event) => {
// // //     if (event.data.size > 0) {
// // //       chunksRef.current.push(event.data);
// // //     }
// // //   };

// // //   // Function to upload the recorded audio
// // //   const uploadAudio = () => {
// // //     if (chunksRef.current.length === 0) {
// // //       console.log("No audio recorded.");
// // //       return;
// // //     }

// // //     const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
// // //     const audioRef = ref(storage, `audios/${v4()}.wav`);
// // //     uploadBytes(audioRef, audioBlob).then((snapshot) => {
// // //       getDownloadURL(snapshot.ref).then((url) => {
// // //         setAudioUrls((prev) => [...prev, url]);
// // //         chunksRef.current = []; // Reset chunks for the next recording
// // //       });
// // //     });
// // //   };

// // //   useEffect(() => {
// // //     listAll(audiosListRef).then((response) => {
// // //       response.items.forEach((item) => {
// // //         getDownloadURL(item).then((url) => {
// // //           setAudioUrls((prev) => [...prev, url]);
// // //         });
// // //       });
// // //     });
// // //   }, []);

// // //   return (
// // //     <div className="App" style={{padding:'100px'}}>
// // //       {recording ? (
// // //         <button onClick={stopRecording}>Stop Recording</button>
// // //       ) : (
// // //         <button onClick={startRecording}>Start Recording</button>
// // //       )}

// // // <button onClick={uploadAudio}>upload</button>

// // //       {audioUrls.map((url) => {
// // //         return (
// // //           <audio controls key={url}>
// // //             <source src={url} type="audio/wav" />
// // //             Your browser does not support the audio element.
// // //           </audio>
// // //         );
// // //       })}
// // //     </div>
// // //   );
// // // }

// // // export default Test;

// // import { useState, useEffect, useRef } from "react";
// // import {
// //   ref,
// //   uploadBytes,
// //   getDownloadURL,
// //   listAll,
// //   deleteObject,
// // } from "firebase/storage";

// // import { v4 } from "uuid";
// // import { storage } from "../Firebase/Firebase";
// // import { AiFillPauseCircle } from "react-icons/ai";
// // import { MdDelete, MdKeyboardVoice } from "react-icons/md";
// // import { RiSendPlane2Fill } from "react-icons/ri";
// // import ReactAudioPlayer from "react-audio-player";

// // function Test() {
// //   const [audioUpload, setAudioUpload] = useState(null);
// //   const [audioUrls, setAudioUrls] = useState([]);

// //   const audiosListRef = ref(storage, "audios/");

// //   // State to manage the recording status
// //   const [recording, setRecording] = useState(false);
// //   const mediaRecorderRef = useRef(null);
// //   const chunksRef = useRef([]);

// //   useEffect(() => {
// //     // Check if the browser supports the MediaRecorder API
// //     if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
// //       console.log("MediaRecorder not supported in this browser.");
// //       return;
// //     }
// //   }, []);

// //   // Function to start recording
// //   const startRecording = () => {
// //     navigator.mediaDevices
// //       .getUserMedia({ audio: true })
// //       .then((stream) => {
// //         mediaRecorderRef.current = new MediaRecorder(stream);
// //         mediaRecorderRef.current.addEventListener(
// //           "dataavailable",
// //           handleDataAvailable
// //         );
// //         mediaRecorderRef.current.addEventListener("stop", handleStop);
// //         mediaRecorderRef.current.start();
// //         setRecording(true);
// //       })
// //       .catch((error) => {
// //         console.error("Error accessing the microphone:", error);
// //       });
// //   };

// //   // Function to stop recording
// //   const stopRecording = () => {
// //     if (
// //       mediaRecorderRef.current &&
// //       mediaRecorderRef.current.state !== "inactive"
// //     ) {
// //       mediaRecorderRef.current.stop();
// //     }
// //     setRecording(false);
// //   };

// //   // Event listener to handle data available from the MediaRecorder
// //   const handleDataAvailable = (event) => {
// //     if (event.data.size > 0) {
// //       chunksRef.current.push(event.data);
// //     }
// //   };

// //   const [recentaudio,setRecentAudio]=useState();

// //   // Event listener to handle stop recording
// //   const handleStop = () => {
// //     // Automatically listen to the recorded audio after stopping
// //     const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
// //     const audioUrl = URL.createObjectURL(audioBlob);
// //     setRecentAudio(audioUrl)
// //     setAudioUrls((prev) => [...prev, audioUrl]);
// //   };

// //   // Function to upload the recorded audio
// //   const uploadAudio = () => {
// //     if (chunksRef.current.length === 0) {
// //       console.log("No audio recorded.");
// //       return;
// //     }

// //     const audioBlob = new Blob(chunksRef.current, { type: "audio/wav" });
// //     const audioRef = ref(storage, `audios/${v4()}.wav`);
// //     uploadBytes(audioRef, audioBlob).then((snapshot) => {
// //       getDownloadURL(snapshot.ref).then((url) => {
// //         setAudioUrls((prev) => [...prev, url]);
// //         chunksRef.current = []; // Reset chunks for the next recording
// //       });
// //     });
// //   };

// //   useEffect(() => {
// //     listAll(audiosListRef).then((response) => {
// //       response.items.forEach((item) => {
// //         getDownloadURL(item).then((url) => {
// //           setAudioUrls((prev) => [...prev, url]);
// //         });
// //       });
// //     });
// //   }, []);

// //   const deleteAudio = (url) => {
// //     setAudioUrls((prev) => prev.filter((audio) => audio !== url));
// //     setRecentAudio('')

// //   };

// //   return (
// //     <div   style={{padding:'100px'}}>
// //       {/* {audioUrls < 1 && ( */}
// //         {/* <>
// //           {recording ? (
// //             <button onClick={stopRecording}>
// //               <div className="audio-r1">
// //                 <AiFillPauseCircle size={20} />
// //                 <h1>Audio Recording</h1>
// //               </div>
// //             </button>
// //           ) : (
// //             <>
// //               <MdKeyboardVoice onClick={startRecording} size={25} />
// //             </>
// //           )}
// //         </> */}
// //       {/* // )}  */}

// //       {/* {recentaudio && (
// //         <div  className="audio-record">
// //           <div className="audio-record2">
// //             <audio autoPlay controls>
// //               <source src={recentaudio} type="audio/wav" />
// //               Your browser does not support the audio element.
// //             </audio>
// //             <MdDelete onClick={() => deleteAudio(recentaudio)} size={20} />

// //             <RiSendPlane2Fill onClick={uploadAudio} size={20} />
// //           </div>
// //         </div>
// //       )} */}

// // <ReactAudioPlayer
// //                                                 className="react-audio-player1"
// //                                                 src={require('../../assets/blob_http___localhost_3001_514339b8-af28-4c9d-9d79-c7124338d573.mp3')}
// //                                                 type="audio/mp3"
// //                                                 controls
// //                                               />

// //     <audio  controls>
// //                                                 <source
// //                                                     src={require('../../assets/blob_http___localhost_3001_514339b8-af28-4c9d-9d79-c7124338d573.mp3')}
// //                                                   type="audio/mp3"
// //                                                 />
// //                                                 {/* Your browser does not support the audio element. */}
// //                                               </audio>

// //                                               <audio controls>
// //   <source src={require('../../assets/blob_http___localhost_3001_514339b8-af28-4c9d-9d79-c7124338d573.mp3')} type="audio/mpeg" />
// //   Your browser does not support the audio element.
// // </audio>

// //     </div>
// //   );
// // }

// // export default Test;
// import React from "react";
// import ReactAudioPlayer from "react-audio-player";
// import ReactPlayer from "react-player";

// const Test = () => {
  
//   return (
//     <div style={{padding:'100px 0px'}}>
//       <audio autoplay controls>
//         <source
//           src={require("../../assets/Behind Barz.mp3")}
//           type="audio/mp3"
//         />
//       </audio>

//       <audio autoplay controls>
//         <source
//           src={require("../../assets/Behind Barz.mp3")}
//           type="audio/mp3"
//         />
//       </audio>
  
  
                                              

//     </div>
//   );
// };

// export default Test;

import React, { useState } from 'react';

const MicrophoneAccess = () => {
  const [stream, setStream] = useState(null);
  
  const getMicrophonePermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(stream);
    } catch (err) {
      console.error('Error accessing the microphone:', err);
    }
  };


  function getLocalStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        window.localStream = stream;
        window.localAudio.srcObject = stream;
        window.localAudio.autoplay = true;
      })
      .catch((err) => {
        console.error(`you got an error: ${err}`);
      });
  }
  
  return (
    <div style={{padding:'100px 0px'}}>
      <button onClick={ getLocalStream}>Get Microphone Access</button>
      {stream && <p>Microphone access granted!</p>}
    </div>
  );
};

export default MicrophoneAccess;
