import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import { db } from '../../../Firebase/Firebase';
import ModuleIconTick from '../ModuleLessonBar/ModuleIconTick';
import '../Modules.css'


const StudyDetailLessonBar = ({item}) => {

    const { user } = useContext(AuthContext);
    const [getUser, setGetUser] = useState([]);

    
  


   const navigate = useNavigate()

    const [module, setModule] = useState();

    useEffect(() => {
      const todosref = collection(db, "ContestPrepAcademy" , item?.id , item?.id);
      const q = query(todosref , orderBy("chapter" , "asc"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let Blog = [];
        querySnapshot.forEach((doc) => {
          Blog.push({
            id: doc.id,
            ...doc.data(),
          });
          setModule(Blog);
        });
      });
  
      return () => unsub();
    }, [item?.id]);



  return (
    <>
    {
        module?.map((data ,index) => (
            <div
            onClick={() =>
                navigate("/Modules/Lessons", {
                  state: { id: data.id , module: item.id },
                })
              }
            className="module-side-a1"
            >
            <div>
              <h1 className="module-side-h1">
                {data.name}
              </h1>
            </div>
            <ModuleIconTick id={item.id} chapter={data.chapter}/>
          </div>
        ))
    }

        </>
  )
}

export default StudyDetailLessonBar