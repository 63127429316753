import React, { useEffect, useState } from "react";
import "./Profile.css";
import Image from "../../Img/user-temp.png";
import { AiFillCamera, AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaUserCircle } from "react-icons/fa";
import { toast } from "react-hot-toast";

import Modal from "react-modal";
import Footer from "../Footer/Footer ";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../../Firebase/Firebase";
import { getDoc, doc, updateDoc, query, collection, where, onSnapshot } from "firebase/firestore";
import "./Account.css";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { BsFillReplyFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Profile = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [profilePicture, setProfilePicture] = useState(Image);
  const [uploadprofilePicture, UploadsetProfilePicture] = useState(Image);


  function handleChange(event) {
    UploadsetProfilePicture(event.target.files[0])
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
  }

  const [toggleState, setToggleState] = useState(1);
  const [toggleState1, setToggleState1] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const toggleTab1 = (index) => {
    setToggleState1(index);
  };

  const [user] = useAuthState(auth);

  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  const [name, setName] = useState("");
  const [Nickname, setNickname] = useState();

  const firstClick = async () => {
    const washingtonRef = doc(db, "usersLesson", user?.uid);
    const washingtonRef1 = doc(db, "users", user?.email.toLocaleLowerCase());

    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      name: name,
      nickname: Nickname,
    }).then(() => {
      toast.success("Updated Successfully");

      updateDoc(washingtonRef1, {
        name: name,
        nickname: Nickname,
      }).then(() => {
        closeModal();
      });
    });
  };




  const uploadProfilePicture1 = async () => {
  
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `user/${new Date().getTime()} - ${uploadprofilePicture.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, uploadprofilePicture);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await updateDoc(doc(db, "usersLesson" , user?.uid ), {
            profileimg: url,
          })
            .then(() => {
              toast("Profile Picture Uploaded Successfully", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
            })

            await updateDoc(doc(db, "users" , getUser?.email ), {
              profileimg: url,
            })
        } catch (err) {
          console.log(err);
        }
      };
     return () => uploadImg();
   
  }

  const [forum ,setForum] = useState({})

  useEffect(() => {
    const q = query(collection(db, "Forum"), where("userId", '==', user.uid));
    const unsub = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForum(cities.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1));
    });

    return () => unsub();
  }, [user.uid]);



  const navigate = useNavigate()

  return (
    <div className="Profile-bg">
      <div className=" body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="grid-c1 main-y">
            <div className="profile-div-1-1  grid-c2 ">
              <div className="profile-div-1-2 ">
                <div className="user-profile-name-pic">
                  <div className="user-profile-name">
                    <div class="profile-div-1 profile-pic">
                      <label class="-label" for="file">
                        <span>
                          <AiFillCamera size={30} />
                        </span>
                      </label>
                      <img
                        src={profilePicture || uploadprofilePicture} 
                        id="output"
                        width="200"
                        alt=""
                      />
                      <input id="file" type="file" onChange={handleChange} />
                    </div>
                    <div className="user-name-profile">
                      <h1>
                        {getUser?.name}
                        <span className="span-1-profile">
                          {/* Joined   <Moment format='MMMM Do YYYY'>{getUser?.createdAt.toDate()}</Moment> • Active now */}
                        </span>
                      </h1>
                      {/* <button onClick={()=> uploadProfilePicture1()}>Update</button> */}
                    </div>
                  </div>
                </div>
                <Tabs className="profile-tab-heads">
                  <TabList>
                    <div className="tab-list-title">
                      <Tab
                        onClick={() => toggleTab(1)}
                        className={toggleState === 1 ? "bb1" : "bb11"}
                      >
                        <h1 className="title-tab-1">Profile</h1>
                      </Tab>

                      <Tab
                        onClick={() => toggleTab(2)}
                        className={toggleState === 2 ? "bb1" : "bb11"}

                        // className="tab-1-profile-f"
                      >
                        <h1 className="title-tab-1">Forums</h1>
                      </Tab>
                    </div>
                  </TabList>

                  <TabPanel>
                    <div className="panel-head-1">
                      <div className="tab-panel-1-profile">
                        <h1>Details</h1>
                        <button
                          onClick={openModal}
                          className="edit-btn-profile"
                        >
                          Edit
                        </button>
                      </div>
                      <hr />
                      <div className="td-profile-1">
                        <tbody className="tbody-profile-1">
                          <tr className="tr-profile-1">
                            <td>Name</td>
                            <td>{getUser?.name}</td>
                          </tr>
                          <tr className="tr-profile-1">
                            <td>Nicname</td>
                            {getUser?.nickname ? (
                              <td>@{getUser?.nickname}</td>
                            ) : (
                              <td>(Click on edit button)</td>
                            )}
                          </tr>
                          <tr className="tr-profile-1">
                            <td>Email</td>
                            <td>{getUser?.email}</td>
                          </tr>
                        </tbody>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Tabs>
                      <TabList>
                        <div className="tab-list-title1">
                          <Tab
                            onClick={() => toggleTab1(1)}
                            className={toggleState1 === 1 ? "bb1" : "bb11"}
                          >
                            <h1 className="title-tab-1">My Discussions</h1>
                          </Tab>
                    
                        </div>
                      </TabList>
                      <hr />

                      <div className="tabs-2-1">
                        <TabPanel>

                          {forum?.length === 0 ? (
                            <>
     <h2 className="h1-tabs-2">
     Forum Discussions Started
   </h2>
   <button
                              onClick={() => navigate('/Forums')}
                              className="New-discussion-btn1"
                            >
                              New discussion{" "}
                            </button>
                            </>

                          ) : (
<>
{Array.isArray(forum)
                              ? forum.map((item, key) => {
                                  return (
                                    <div>
                                      <div
                                        onClick={() =>
                                          navigate(`/Reply/${item.id}`)
                                        }
                                        className="comment-1 border-proff"
                                      >
                                        
                                        <div className="comm-gf">
                                          <FaUserCircle className="svg-user" />

                                          <div className="updates-time-rep">
                                            <h1 className="h-h1">
                                              {item.name}
                                            </h1>
                                            <div className="span-updates">
                                              <span className="hov-span1">
                                                <span>
                                                  <BsFillReplyFill className="svg-fill" />
                                                </span>
                                                <span>{item.user}</span>
                                              </span>
                                              <span></span>
                                              <span className="hov-span1">
                                                {moment(
                                                  item.createdAt
                                                ).fromNow()}
                                              </span>

                                              <span>
                                                · {item.replies} Replies
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="comm-gf2">
                                          {item?.verified === 'approved' ? (

                                            <>
                            
<button className="comm-gf2-button1">{item?.verified}</button>
                                            </>

                                          ) : (
<>
{item.verified === 'declined' ? (
                                          <button className="comm-gf2-button2">{item.verified}</button>

                                          ) : (
                                            <button className="comm-gf2-button">{item.verified}</button>

                                          )}
</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
</>
                          )}
                     
                       

                          {/* <div className="all-Discussions-tab1">
                          <h2 className="heading-tabs-2">All Discussions</h2>
                          <hr />

                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="tab-data-5">
                            <div>
                              <FaUserCircle size={30} />
                            </div>

                            <div className="tab-data-6">
                              <div>
                                <h1 
                                 onClick={()=> navigate('/Reply')}
                                className="tab-data-6-h1">
                                  Making the weight class
                                </h1>
                                <div className="section-1-p">

                                <div className="forum-reply-profile">
                                  <BsFillReplyFill size={30} />
                                  <h1 className="tab-data-6-h2">
                                    <span 
                                    onClick={()=> navigate('/Reply')}
                                    className="profile-span-2">John </span>
                                    replied
                                    <span
                                     onClick={()=> navigate('/Reply')}
                                    className="profile-span-3"
                                    >1 month, 1 week ago </span>3 Members ·
                                    6 Replies
                                  </h1>
                                </div>
                              <div>
                       <button
                        onClick={()=> navigate('/')}
                       
                       className="btn-prep-pro">Contest Prep</button>
                                    </div>
                              </div>

                              </div>
                            </div>
                          </div>
                        </div> */}
                        </TabPanel>
                    
                      </div>
                    </Tabs>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="grid-c3">
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          {/* <button onClick={closeModal}>close</button> */}

          <div className="header-modal-edit2">
            <div className="user-dp-modal-reply">
              <FaUserCircle />
            </div>
            <div className="heading-h1-edit">
              <h1>Edit "Details" Information</h1>
            </div>

            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
          </div>

          <hr />
          {/* textarea-modal */}
          <div>
            <div className="profile-edit-setting">
              <div>
                <label className="label-1acc">Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="input-box-1-acc"
                  type="name"
                  name="name"
                  id="name"
                  required
                />
              </div>
              <div>
                <label className="label-1acc">Nickname</label>
                <input
                  value={Nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  className="input-box-1-acc"
                  type="name"
                  name="nickname"
                  id="nickname"
                  required
                />
              </div>
            </div>

            <div className="save-change-btn-div">
              <button
                disabled={!name || !Nickname}
                onClick={() => firstClick()}
                className="save-change-btn"
              >
                Save Change
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
